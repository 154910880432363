import React from "react"
import { graphql } from "gatsby"
import AboutTemplate from "../templates/about-template"
import CampaignTemplate from "../templates/campaign-template"
import DefaultTemplate from "../templates/default-template"
import FeedTemplate from "../templates/feed-template"
import NewsListingTemplate from "../templates/news-listing-template"

const getTemplate = (contentfulPage) => {
  const { template } = contentfulPage

  switch (template) {
    case "about":
      return <AboutTemplate {...contentfulPage} />
    case "campaign":
      return <CampaignTemplate {...contentfulPage} />
    case "feed":
      return <FeedTemplate {...contentfulPage} />
    case "news":
      return <NewsListingTemplate {...contentfulPage} />
    default:
      return <DefaultTemplate {...contentfulPage} />
  }
}

const Page = ({ data: { contentfulPage } }) => {
  return <main>{getTemplate(contentfulPage)}</main>
}

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      template
      title
      subTitle {
        subTitle
      }
      introductionParagraph {
        introductionParagraph
      }
      introduction
      clientQuotes {
        quote {
          quote
        }
        author
      }
      headerImage {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      feedType
      mainContent {
        raw
      }
      mainContentBlock {
        ... on ContentfulTextBlock {
          id
          internal {
            type
          }
          title
          singleColumn
          mainContent {
            raw
          }
        }
        ... on ContentfulAlignedImageBlock {
          id
          internal {
            type
          }
          title
          alignment
          mainText {
            raw
          }
          images {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulImageBlock {
          internal {
            type
          }
          title
          image {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulVideoBlock {
          internal {
            type
          }
          title
          videoUrl
          description
        }
      }
    }
  }
`

export default Page
