import { graphql, useStaticQuery } from "gatsby"

const useAllNews = () => {
  const {
    allContentfulNews: { nodes },
  } = useStaticQuery(graphql`
  query allContentfulNewsQuery {
    allContentfulNews(
      sort: {fields: datePublished, order: DESC}
      filter: {enabled: {eq: true}}
    ) {
      nodes {
        internal {
          type
        }
        enabled
        title
        datePublished
        url
        thumbnail {
          description
          gatsbyImageData(
            width: 384
            height: 421
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
          file {
            url
          }
        }
      }
    }
  }
  `)

  return nodes
}

export default useAllNews;