import styled from "styled-components";

export const FlexibleRichTextBoxStyles = styled.section`
    position: relative;
    padding: 0 var(--borderSpacing);

    .container {
        height: 100%;
        display: block; // Ensure column-count is supported
        padding: 30px;
        background-color: #efecea;

        p {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            color: var(--textBlack);
            margin-top: 0px;
            margin-bottom: 20px;
        }

        &:not(.single) {
            column-count: 2;
            column-gap: 4%;

            p {
                page-break-inside: avoid;
                break-inside: avoid-column;
            }
        }

        @media (max-width: 768px) {
            padding: 30px 0;
        }
    }

    .container.single {
        column-count: unset;

        p {
            display: block;
            width: 100%;
            color: var(--textBlack);
        }
    }

    @media (max-width: 767px) {
        .container:not(.single) {
            column-count: 1; // Forces a single column layout on smaller screens
        }
    }

    @media (min-width: 1024px) {
        .container {
            padding: 30px 112px 60px 112px;
        }
    }

    @media (min-width: 1680px) {
        .container {
            max-width: 1380px;
        }
    }

    @media (min-width: 1480px) {
        padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    }

    h1, h2 {
        margin-top: 0;
        display: block;
        font-weight: 700;
        letter-spacing: -2px;
    }

    h1 {
        font-size: 85px;
        line-height: 81px;
        @media (max-width: 1024px) {
            font-size: 48px;
            line-height: 47px;
        }
        @media (max-width: 620px) {
            font-size: 32px;
            line-height: 33px;
        }
    }

    h2 {
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
    }

    @media (max-width: 1024px) {
      background-color: rgb(239, 236, 234);
    }
`;