import * as React from "react"
import { SimpleBannerStyles } from "./SimpleBannerStyles"


const SimpleBanner = ({ children, title, subTitle, introductionParagraph, hasDot=true }) => {
  return (
    <SimpleBannerStyles>
      {children}
      {title && (
        <div className="container">
          <div className="banner__content">
            <div className="leftPanel">
            <h1>
              {title}
              {hasDot && <span style={{ color: "var(--bodyColor)" }}>.</span>}
            </h1>
            {subTitle && <h2>{subTitle}</h2>}
            {introductionParagraph && <p>{introductionParagraph}</p>}
            </div>
            <div className="rightPanel"></div>
          </div>
        </div>
      )}
    </SimpleBannerStyles>
  )
}

export default SimpleBanner
