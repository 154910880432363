import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import useWindowSize from '../../hooks/use-window-size';
import { createYoutubeEmbedUrl, createVimeoEmbedUrl } from '../../utils/video-embeds';

const YoutubeEmbed = ({ src = '', className }) => {
    const youtubeRatio = 16 / 9;
    const windowSize = useWindowSize();
    const iframe = useRef(null);
  
    const [videoDimensions, setVideoDimensions] = useState({
      width: iframe.current ? iframe.current.clientWidth : null,
      height: iframe.current ? iframe.current.clientWidth / youtubeRatio : null,
    });
  
    useEffect(() => {
      let currentVideoWidth = iframe.current ? iframe.current.clientWidth : null;
      setVideoDimensions({
        width: currentVideoWidth,
        height:
          currentVideoWidth !== null ? currentVideoWidth / youtubeRatio : null,
      });
    }, [youtubeRatio, windowSize]);
  
    return (
      <iframe
        src={createYoutubeEmbedUrl(src)}
        title="youtube-iframe"
        width={videoDimensions.width !== null ? videoDimensions.width : undefined}
        height={
          videoDimensions.height !== null ? videoDimensions.height : undefined
        }
        style={{ border: 'none', overflow: 'hidden' }}
        seamless
        ref={iframe}
        className={className}
        allowFullScreen={true}
      ></iframe>
    );
  };
  
  export const YouTubeEmbedAtom = styled(YoutubeEmbed)`
    border: 0;
    display: block;
    margin: 0 auto;
    width: 100%;
  `;

const VimeoEmbed = ({ src = '', className }) => {
  const vimeoRatio = 16 / 9;
  const windowSize = useWindowSize();
  const iframe = useRef(null);
  
  const [videoDimensions, setVideoDimensions] = useState({
    width: iframe.current ? iframe.current.clientWidth : null,
    height: iframe.current ? iframe.current.clientWidth / vimeoRatio : null,
  });
  
  useEffect(() => {
    let currentVideoWidth = iframe.current ? iframe.current.clientWidth : null;
    setVideoDimensions({
       width: currentVideoWidth,
       height:
       currentVideoWidth !== null ? currentVideoWidth / vimeoRatio : null,
    });
  }, [vimeoRatio, windowSize]);

  return (
    <iframe
      src={createVimeoEmbedUrl(src)}
      title="vimeo-iframe"
      width={videoDimensions.width !== null ? videoDimensions.width : undefined}
        height={
          videoDimensions.height !== null ? videoDimensions.height : undefined
        }
        style={{ border: 'none', overflow: 'hidden' }}
        seamless
        ref={iframe}
        className={className}
        allowFullScreen={true}
        allow="autoplay"
    ></iframe>
  );
}

export const VimeoEmbedAtom = styled(VimeoEmbed)`
    border: 0;
    display: block;
    margin: 0 auto;
    width: 100%;
  `;