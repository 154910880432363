import styled from "styled-components";

export const VideoBlockStyles = styled.section`
  padding: 0;

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
  }

  .video-block-container {
    width: 100%;
    padding: 30px 112px 60px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(239, 236, 234);

    h2 {
        display: block;
        margin-bottom: 37px;
    }

    @media (max-width: 1024px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (min-width: 1680px) {
      max-width: 1380px;
    }
  }
`;
