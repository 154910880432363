import styled from "styled-components"

export const BannerModuleStyles = styled.section`
  position: relative;
  padding: 0;

  @media (min-width: 1480px) {
    padding: 30px var(--borderSpacing) 0 var(--borderSpacing);
  }

  .container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 112px 80px 112px;
    background-color: #efecea;

    @media (min-width: 1680px) {
      max-width: 1380px;
    }
  }

  @media (max-width: 768px){
      .container {
        padding: 0px 30px 80px 30px;
      }
    }

  .gradient,
  .banner__image {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .banner__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    margin-top: 80px;
    z-index: 2;
    min-height: 33vh;
    width: 100%;

    .leftPanel {
      width: 780px;
      h1 {
        font-size: 85px;
        line-height: 81px;

        @media (max-width: 1024px) {
          padding-right: 30px;
          font-size: 34px;
          line-height: 47px;
        }

        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -1px;
        }
      }
    }

    .rightPanel {
      width: 464px;
      h2{
        margin-left: 64px;
        font-size: 29px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: -0.8px;
        text-align: left;
      }
      @media (max-width: 1024px) {
        padding-bottom: 6px;
        h2{
          margin-left: 0px;
          font-size: 18px;
          line-height: 24px;
        }
      }
      @media (max-width: 768px) {
        padding-bottom: 0px;
      }
    }

    @media (min-width: 768px) {
      margin-top: 120px;
      width: 100%;
    }

    @media (max-width: 1024px) {
      margin-top: 30px;
    }

    @media (max-width: 768px) {
      align-items: flex-start;
      min-height: 0px;
      margin-top: 130px;
    }

    h1,
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      color: #332C28;
    }

    h1 {
      display: block;
      max-width: 726px;
    }

    h2 {
      font-size: 30px;
      font-weight: semi-bold;
      letter-spacing: -0.8px;
      line-height: 38px;
      max-width: 462px;
      float: right;
    }

    h1,
    .price {
      margin-top: 0;
      font-size: 85px;
      line-height: 81px;
      font-weight: 700;
      letter-spacing: -2px;

      @media (max-width: 1024px) {
        font-size: 46px;
        line-height: 47px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }

    }
  }

  &.default-page {
    .container {
      padding-bottom: 50px;
    }
    .banner__content {
      h1 {
        font-size: 70px !important;
        font-weight: 700;
      }
      h2 {
        max-width: 500px;
        margin-top: 50px;
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 700;
        float: none;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
      p {
        max-width: 420px;
      }
    }
    }

  .banner__btns {
    display: flex;
    gap: var(--gap);
  }

`
