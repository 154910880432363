import * as React from "react"
import { Link, navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import { FaSleigh } from "react-icons/fa"

const BannerModule = ({ children, title, subTitle, introductionParagraph, price, enquire, hasDot=false, className }) => {
  function scrollToArea() {
    navigate("#topContent")
  }

  const phraseToUnderline = "Composites and Advanced Materials";

  const underlinedSubtitle = subTitle.replace(
    new RegExp(phraseToUnderline, 'g'),
    `<span style="padding-bottom: 2px; border-bottom: 2px solid #f6d134;">${phraseToUnderline}</span>`
  );

  return (
    <>
      <BannerModuleStyles className={className}>
        {children ? (
          children
        ) : (
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../../../static/macbook-color.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        )}

        <div className="container">
          <div className="banner__content">
            {title && (
              <div className="leftPanel">
              <h1>
                {title}
                {hasDot && <span style={{ color: "var(--primary)" }}>.</span>}
              </h1>
              </div>
            )}
            {subTitle && <div className="rightPanel"><h2 dangerouslySetInnerHTML={{ __html: underlinedSubtitle }} /></div>}
            {introductionParagraph && <p>{introductionParagraph}</p>}

            {/* {price && (
              <h2 className="price">
                £{price}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>
            )} */}
            {/* <div className="banner__btns">
              {enquire && (
                <Button
                  className="btn"
                  text="Enquire Now"
                  as={Link}
                  to="/contact"
                />
              )}
              <Button onClick={scrollToArea} text="Learn More" />
            </div> */}
          </div>
        </div>
        {/* <div className="gradient"></div> */}
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
