import { graphql, useStaticQuery } from "gatsby"

const useAllClients = () => {
  const {
    allContentfulClients: { nodes },
  } = useStaticQuery(graphql`
    query allContentfulClientsQuery {
        allContentfulClients(sort: {order: ASC, fields: position}) {
            nodes {
              internal {
                type
              }
              title
              position
              logo {
                file {
                  url
                }
              }
              website
            }
        }
    }
  `)

  return nodes
}

export default useAllClients;