import styled from "styled-components"

export const TileBlockStyles = styled.section`

        padding: 0;
        margin-bottom: 0;

        > .tileSection {
            margin-bottom: 0;
        }

        @media(min-width: 1024px) {
            padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
            > .tileSection {
                margin-bottom: 0;
            }
        }

        .tile-container {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            padding: 76px 112px;
            background-color: rgba(247, 246, 245, 1);

            @media (min-width: 1680px) {
                max-width: 1380px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .tile {
            flex: 0 0 calc(33.333% - 32px); /* Adjust margins as needed */
            margin: 0 0 0 32px; /* Spacing between tiles */
            min-height: 452px; /* Adjust based on your content */
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0; /* Start invisible */
            transform: translateY(20px); /* Start slightly translated down */
            transition: transform 0.8s ease; /* Smooth transition for the scroll effect */
        }

        /* Remove left margin for the first tile in each row */
        .tile-container > .tile:nth-child(3n+1) {
            margin-left: 0;
        }

        /* Adjust the top margin for staggered tiles */
        .tile-container > .tile:nth-child(3n+2) {
            margin-top: 100px; /* 100px top margin for the second tile in the first row */
        }

        @keyframes fadeInUp {
            from {
                opacity: 0;
                transform: translateY(20px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .tile.animate-in {
            animation: fadeInUp 0.5s forwards;
        }

        /* Responsive adjustments */
        @media (max-width: 1024px) { /* Larger mobile devices */
            .tile {
                flex: 0 0 calc(50% - 32px); /* 2 tiles per row, adjust margins as needed */
            }
            .tile-container {
                padding: 38px;
            }
            .tile-container > .tile:nth-child(2n+1) {
                margin-left: 0; /* First tile in a row for 2-column layout */
            }
            .tile-container > .tile:nth-child(n+3):nth-child(2n+2) {
                /* Adjust if needed for staggered tiles in 2-column layout */
                margin-top: 100px;
            }
        }

        @media (max-width: 768px) {
            .tile {
                flex: 0 0 100%; /* 1 tile per row */
                margin-left: 0; /* Remove left margin for single column */
                margin-top: 0;
                margin-bottom: 32px;
            }
            .tile-container > .tile:nth-child(3n+2) {
                margin-top: 0;
            }
            .tile-container > .tile:nth-child(n+3):nth-child(2n+2) {
                margin-top: 0px;
            }
        }
    
`;