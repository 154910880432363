import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TileStyles } from './TileStyles';
import { CallToAction } from '../TileSlider/TileSliderStyles';
import { ellipsisText } from '../../utils/string-helper-functions';

const Tile = ({tile}) => {
  return (
    <TileStyles>
        <GatsbyImage image={getImage(tile.gatsbyImageData)} alt={tile?.imageAlt || null} />
        {/* <img src={tile.imageUrl} alt={tile?.imageAlt || null} /> */}
        <div className="content">
            <h3>{ellipsisText(tile.title)}</h3>
            <div className="readMe">{tile.callToActionText && tile.callToActionLink && <CallToAction url={tile.callToActionLink}>{tile.callToActionText}</CallToAction>}</div>
        </div>
    </TileStyles>
  );
};

export default Tile;