import React from "react"
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import styled from "styled-components";
import BannerQuote from "../BannerQuote/BannerQuote";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LargeImageSliderStyles, SliderContent } from '../LargeImage/LargeImageSliderStyles';

const QuoteSlider = ({slides}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <LargeImageSliderStyles className="section" bgColour="var(--primary)">
    <div className="container no-top-border">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <SliderContent key={index}>
            <BannerQuote quote={slide.quote.quote} author={slide.author} />
          </SliderContent>
        ))}
      </Slider>
      </div>
    </LargeImageSliderStyles>
  );
};

// Previous Arrow component
const PrevArrow = (props) => {
    const { onClick } = props;
    return <Arrow onClick={onClick} left className="hide-arrow"><SlArrowLeft /></Arrow>;
  };
  
  // Next Arrow component
  const NextArrow = (props) => {
    const { onClick } = props;
    return <Arrow onClick={onClick} right className="hide-arrow"><SlArrowRight /></Arrow>;
  };
  
  const Arrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2px;
    padding: 6px 8px 0px;
    background-color: #f6d134;
    font-size: 34px;
    color: #000;
    cursor: pointer;
    z-index: 1;
    ${({ left }) => left && 'left: 3.5%;'};
    ${({ right }) => right && 'right: 3.5%;'};
    &.hide-arrow {
      display: none;
    }
  `;

  export default QuoteSlider;