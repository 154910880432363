export const createYoutubeEmbedUrl = url => {
    let id;
    const options = 'wmode=opaque&rel=0&showinfo=0&modestbranding=1&color=white';
    if (url.includes('embed')) {
      return `${url}?${options}`;
    }
    if (url.includes('?v=')) {
      id = url.split('?v=')[1].split('&')[0];
    }
    if (url.includes('youtu.be/')) {
      id = url.split('youtu.be/')[1];
    }
    return id
      ? `https://www.youtube.com/embed/${id}?${options}`
      : console.error(`Invalid youtube link input ${url}`);
  };

  export const createVimeoEmbedUrl = url => {
    let id;
    const options = 'autoplay=1&muted=1';
    if (url.includes('video/')) {
      id = url.split('video/')[1];
    }
    return id
      ? `https://player.vimeo.com/video/${id}?${options}`
      : console.error(`Invalid vimeo link input ${url}`);
  };