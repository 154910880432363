import styled from "styled-components"

export const BannerQuoteStyles = styled.section`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px var(--borderSpacing) 0 var(--borderSpacing);
  background-color: var(--primary);

  .container {
    max-width: 1044px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 80px 0px 80px 0px;
  }

    h2 {
        display: block;
        font-size: 55px;
        line-height: 66px;
        font-weight: 400;
        letter-spacing: -2px;
        text-align: center;

        @media (max-width: 1024px) {
          font-size: 35px;
          line-height: 46px;
        }
    }

    p {
      display: block;
      font-size: 18px;
      line-height: 40px;
      font-weight: 600;
      letter-spacing: -0.8px;
      color: var(--textBlack);
      text-align: center;
    }
`
