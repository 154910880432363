import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

export const RichTextRenderer = ({ richTextJson, references = [] }) => {
  const content = JSON.parse(richTextJson);

  // Map for easy lookup of references
  const referenceMap = references.reduce((acc, ref) => {
    acc[ref.contentful_id] = ref;
    return acc;
  }, {});

  const options = {
    renderNode: {
      // Default paragraph rendering
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      // Handling embedded assets
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { id } = node.data.target.sys;
        const asset = referenceMap[id];
        if (asset && asset.gatsbyImageData) {
          const image = getImage(asset.gatsbyImageData);
          return (
            <div style={{ width: '100%', display: 'block', margin: '20px 0' }}>
            <GatsbyImage
              image={image}
              alt={asset.description || asset.title || 'Embedded Asset'}
              style={{ maxWidth: '100%' }}
            />
            </div>
          );
        }
        return null; // Placeholder or alternative for missing assets
      },
      // Add other node types if necessary
    },
    renderMark: {
      // Bold text
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      // Italic text
      [MARKS.ITALIC]: text => <em>{text}</em>,
      // Add other marks if necessary
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return <>{documentToReactComponents(content, options)}</>;
};