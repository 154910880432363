import * as React from "react";
import { RichTextRenderer } from "../RichTextRenderer/RichTextRenderer";
import { FlexibleRichTextBoxStyles } from "./FlexibleRichTextBoxStyles";

export const FlexibleRichTextBox = ({ title, singleColumn, content }) => {
  return (
    <FlexibleRichTextBoxStyles>
        {content && content.raw && (
          <div className={`container${singleColumn ? ' single' : ''}`}>
              <RichTextRenderer
                richTextJson={content.raw}
                references={content.references || []}
              />
          </div>
        )}
    </FlexibleRichTextBoxStyles>
  )
}