import styled from "styled-components"

export const ServicesStyles = styled.section`
  width: 100%;
  margin-bottom: 0 !important;

  @media(max-width: 1024px) {
    padding: 0 !important;
  }

  > .container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px 80px;
    background: var(--primary);

    @media(min-width: 768px) {
      padding: 0 0 120px 112px;
    }

    @media (min-width: 1680px) {
      max-width: 1380px;
    }

    h2 {
      font-size: 72px;
      line-height: 82px;
      font-weight: 700;
      letter-spacing: -2px;
      margin-top: 0;
      padding-top: 0;

      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 33px;
      }
    }

    h3 {
      max-width: 641px;
      font-size: 30px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: -0.8px;
      color: rgb(51, 44, 40);
      margin-top: 0px;
    }
  }
  `

  export const ServicesHomeSliderStyles = styled.section`
    ul {
      margin: 45px 0 0 0;
      padding-left: 0;

      li {
        position: relative;
        list-style: none;
        font-size: 34px;
        font-weight: bold;
        margin-bottom: 25px;
        max-width: 90%;

        @media (max-width: 1024px) {
            font-size: 26px;
            margin-bottom: 15px;
        }
        
        a {
          font-size: 34px;
          font-weight: bold;
          text-decoration: none;
          color: rgb(51, 44, 40);

          @media (max-width: 1024px) {
            font-size: 26px;
          }

          @media (max-width: 940px) {
            font-size: 22px;
          }
        }

        a:hover {
           opacity: .7;

           &:after {
            content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M25.3431 0.928877L31.7071 7.29284C32.0976 7.68336 32.0976 8.31653 31.7071 8.70705L25.3431 15.071C24.9526 15.4615 24.3195 15.4615 23.9289 15.071C23.5384 14.6805 23.5384 14.0473 23.9289 13.6568L28.5858 8.99995L7.31201e-07 8.99995L5.56355e-07 6.99995L28.5858 6.99995L23.9289 2.34309C23.5384 1.95257 23.5384 1.3194 23.9289 0.928878C24.3195 0.538353 24.9526 0.538353 25.3431 0.928877Z" fill="%23333333"%3E%3C/path%3E%3C/svg%3E');
            background-size: contain;
            position: absolute;
            bottom: 0;
            right: 0;
            }
        }
      }
    }
    

    .slider-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .left-panel {
      flex: 1 0 40%;
      @media (max-width: 768px) {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    .right-panel {
      position: relative;
      flex: 1 0 60%;

      @media (max-width: 768px) {
        display: none;
        flex: 0 0 100%;
      }
    }

    .image-wrapper {
      position: relative;
      margin-top: 48px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      opacity: 1;
      /* transition: opacity 0.5s ease-in-out; */
      .overlay {
        position: absolute;
        background-color: rgb(0,0,0,.5);
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      .service-text {
        position: absolute;
        width: 360px;
        z-index: 200;
        bottom: 122px;
        left: 66px;
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: var(--primary);
        }
      }
      .service-cta {
        position: absolute;
        z-index: 200;
        bottom: 66px;
        left: 66px;
        a {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          color: var(--primary);
        }
      }

      @media (max-width: 768px) {
        margin-top: 0;
        .service-text {
          width: 90%;
          bottom: 40px;
          left: 20px;
        }
        .service-cta {
          bottom: 20px;
          left: 20px;
        }
      }
    }

    /* .right-panel.fade-in .image-wrapper {
      opacity: 1;
    }

    .right-panel.fade-out .image-wrapper {
      opacity: 0;
    } */

    /* Styling for small screens (less than 768px) */
    @media (max-width: 768px) {
      .slider-container {
        flex-direction: column;
      }

      .right-panel {
        margin-top: 20px; /* Add some space between left and right panels on small screens */
      }
  }
`