import React from "react";
import { ServicesStyles } from "./ServicesStyles";
import ServicesHomeSlider from "./ServicesHomeSlider";

const Services = ({ title, subheading, className = "" }) => {
    return (
        <ServicesStyles className={`section ${className}`}>
            <div className="container">
                <div>
                <ServicesHomeSlider title={title} subheading={subheading} className={className} />
                </div>
            </div>
        </ServicesStyles>
      )
}

export default Services;