import styled from "styled-components"

export const SimpleBannerStyles = styled.section`
  /* height: 70vh; */
  position: relative;

  .container {
    position:relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 110px 30px 80px 30px;
    background-color: #efecea;

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 40%;
      border-bottom: 3px solid var(--primary);
    }
  }

  @media (min-width: 769px) {
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1380px;
    }
  }

  .banner__image,
  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .banner__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .leftPanel, .rightPanel {
      @media (min-width: 768px) {
        width: 50%;
      }
      width: 100%;
    }

    h1 {
      margin-top: 0;
      font-size: 85px;
      line-height: 88px;
      font-weight: 700;
      letter-spacing: -2px;
      display: inline-block;
    
      @media (max-width: 1024px) {
        font-size: 48px;
        line-height: 47px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }
    }

    h2 {
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
        display: inline-block;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: var(--textBlack);
    }
  }
`
