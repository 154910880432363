import * as React from "react"
import { BannerQuoteStyles } from "./BannerQuoteStyles"

const BannerQuote = ({ quote, author }) => {
  return (
    <BannerQuoteStyles>
      {quote && (
        <div className="container">
          <div className="banner__content">
            <div>
                <h2>{quote}</h2>
                <p>{author}</p>
            </div>
          </div>
        </div>
      )}
    </BannerQuoteStyles>
  )
}

export default BannerQuote;
