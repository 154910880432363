import React from 'react';
import { YouTubeEmbedAtom, VimeoEmbedAtom } from '../../atoms/Media/media-atoms';
import { VideoBlockStyles } from './VideoBlockStyles';

export const VideoBlock = ({
    title =  '',
    mediaSrc = '',
    description = '',
    className,
  }) => {
    let content;
    if (mediaSrc && mediaSrc.length > 0) {

      if (mediaSrc.includes('youtube') || mediaSrc.includes('youtu.be')) {
        content = (
          <div className="youtube-container">
            <YouTubeEmbedAtom className={className} src={mediaSrc} />
          </div>
        );
      } else if (mediaSrc.includes('vimeo')) {
        content = (
          <div className="vimeo-container">
            <VimeoEmbedAtom className={className} src={mediaSrc} />
          </div>
        );
      }

      if (!content) {
        console.error(`Media Block called with invalid source: ${mediaSrc}`);
        return null;
      }

      return (
        <VideoBlockStyles>
          <div className="video-block-container">
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
            <div className={className}>
              {content}
            </div>
          </div>
        </VideoBlockStyles>
      );

    } else {
      console.error(`Media Block called without a media source`);
      return null;
    }
  };