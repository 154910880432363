import { graphql, useStaticQuery } from "gatsby";

const useAllServiceSlider = () => {
  const {
    allContentfulService: { nodes },
  } = useStaticQuery(graphql`
    query allServicesSliderQuery {
        allContentfulService(
          sort: {fields: order, order: ASC}
          filter: {enabled: {eq: true}}
        ) {
            nodes {
              internal {
                type
              }
              title
              enabled
              url
              listingImage {
                description
                gatsbyImageData(
                  width: 384
                  height: 421
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                file {
                  url
                }
              }
            }
        }
    }
  `)

  return nodes;
}

export default useAllServiceSlider;