import React, { useEffect, useRef } from 'react';
import { TileBlockStyles } from './TileBlockStyles';
import Tile from '../../components/Tile/Tile';
import { transformTileContent } from '../../utils/transform-data-functions';


const TileBlock = ({tiles}) => {
  const transformedTileData = transformTileContent(tiles);
  const containerRef = useRef(null);
  
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute('data-index'), 10);
            const delay = (index % 3) * 0.2; // Adjust 0.2s delay increment based on column position
            entry.target.style.animationDelay = `${delay}s`;
            entry.target.classList.add('animate-in');
          }
        });
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      });

      const tiles = containerRef.current ? containerRef.current.querySelectorAll('.tile') : [];
      tiles.forEach(tile => observer.observe(tile));

      return () => observer.disconnect();
    }
  }, [tiles]); // Re-run when tiles change

  return (
    <TileBlockStyles className="tileSection" ref={containerRef}>
      <div className="tile-container">
        {transformedTileData.map((tile, index) => (
          <div key={index} className="tile" data-index={index}>
            <Tile tile={tile} />
          </div>
        ))}
      </div>
    </TileBlockStyles>
  );
};

export default TileBlock;