import * as React from "react";
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SimpleBanner from "../components/SimpleBanner/SimpleBanner";
import TileBlock from "../Blocks/TileBlock/TileBlock";
import useAllClients from '../hooks/use-all-clients';
import useAllCampaigns from '../hooks/use-all-campaigns';


const CampaignTemplate = contentfulPage => {

  const clients = useAllClients();
  const tiles = useAllCampaigns();

  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title} subTitle={contentfulPage.subTitle?.subTitle} hasDot={true}>
        </SimpleBanner>
        <TileBlock tiles={tiles} />
        <ClientSlider heading="Clients." logos={clients} />
      </Layout>
    </>
  )
}

export default CampaignTemplate