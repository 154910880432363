import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ImageBlockStyles } from './ImageBlockStyles';

export const ImageBlock = ({
  title = '',
  image,
}) => {
  if (image) {
    const gatsbyImage = getImage(image);
    return (
      <ImageBlockStyles>   
          <div className="image-block-container">
            {title && <h2>{title}</h2>}
            {gatsbyImage ? <GatsbyImage image={gatsbyImage} alt={image?.description || null} /> : image && image?.file?.url && <img src={image?.file?.url} alt={image?.description} />}
          </div>
      </ImageBlockStyles>
    );
  }
  return null;
};