import React from 'react';
import { AlignedImageBlockStyles } from './AlignedImageBlockStyles';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RichTextRenderer } from '../../components/RichTextRenderer/RichTextRenderer';
import PropTypes from 'prop-types';

export const AlignedImageBlock = ({
  title = '',
  content,
  images,
  alignment,
}) => {

  if (images) {
    return (
      <AlignedImageBlockStyles>   
          <div className="aligned-image-block-container">
            {title && <h2>{title}</h2>}
            <div className="aligned-image-block-content" style={{ flexDirection: alignment ? 'row' : 'row-reverse' }}>
            <div className="aligned-image-block-images">
                {images.map((image, index) => {
                  const imageData = getImage(image);
                  return imageData && (
                    <GatsbyImage 
                      key={index} 
                      image={imageData} 
                      alt={`Content ${index}`} 
                    />
                  );
                })}
              </div>
              <div className="aligned-image-block-text">
                {content && content.raw && (
                  <RichTextRenderer
                    richTextJson={content.raw}
                    references={content.references || []}
                  />
                )}
              </div>
            </div>
          </div>
      </AlignedImageBlockStyles>
    );
  }
  return null;
};

AlignedImageBlock.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  content: PropTypes.object.isRequired,
  imagesFirst: PropTypes.bool,
};

AlignedImageBlock.defaultProps = {
  imagesFirst: true,
};