import { graphql, useStaticQuery } from "gatsby"

const useAllCampaigns = () => {
  const {
    allContentfulCampaign: { nodes },
  } = useStaticQuery(graphql`
    query allContentfulCampaignsQuery {
        allContentfulCampaign {
            nodes {
              internal {
                type
              }
              title
              thumbnail {
                description
                gatsbyImageData(
                  width: 384
                  height: 421
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                file {
                  url
                }
              }
              caseStudy
              internalLink {
                internal {
                  type
                }
                url
              }
              externalLink
            }
        }
    }
  `)

  return nodes
}

export default useAllCampaigns;