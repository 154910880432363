import styled from "styled-components";

export const AlignedImageBlockStyles = styled.section`
  padding: 0;

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
  }

  .aligned-image-block-container {
    width: 100%;
    padding: 30px 112px 60px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(239, 236, 234);

    @media (max-width: 1024px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (min-width: 1680px) {
      max-width: 1380px;
    }
  }

  h2 {
    margin-bottom: 37px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--textBlack);
    margin-bottom: 20px;
  }

  .aligned-image-block-content {
    display: flex;
    gap: 37px;

    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 0px;
    }
  }

  .aligned-image-block-images {
    flex: 10%;
  }

  .aligned-image-block-images img {
    width: 100%;
    margin-bottom: 37px;
  }

  .aligned-image-block-text {
    flex: 50%;
  }
`;
