import styled from "styled-components";
import { Link } from 'gatsby';

export const LargeImageSliderStyles = styled.section`
  padding: 0;
  background-color: $var(--background);

  @media(max-width: 1480px) {
    background-color: ${(props) => props.bgColour || 'var(--background)'};
  }

  &.section {
    margin-bottom: 0 !important;
    margin: 0;
    padding: 0;
  }

  @media(min-width: 1480px) {
    &.section {
      padding: 0 var(--borderSpacing);
    }
  }


  > .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 80px 0px;
    background: linear-gradient(to bottom, #efecea 50%, var(--primary) 50%);

    :before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      width: 50%;
      border-top: 3px solid var(--primary);
    }

    .gatsby-image-wrapper {
        max-height: 780px;
    }
        
    > * {
      width: 100%;
      max-height: 780px;
    }

    .video-container {
      position: relative;
      width: 100%; /* Ensure the container is full width */
      padding-top: 56.25%; /* 16:9 Aspect Ratio */

      iframe {
        border: none;
      }

      .video-loading {
        position: absolute;
        display: flex;          /* Enables Flexbox */
        justify-content: center; /* Centers content along the main axis (horizontal) */
        align-items: center; 
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: rgb(33, 33, 33);
      }
    }

    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (min-width: 1680px) {
      max-width: 1380px;
    }

  }

  > .container > .no-top-border {
    width: 100%;
  }

  > .container.no-top-border:before {
      border-top: none !important;
  }
`;

export const SliderContent = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);

  .overlay-content {
    position: absolute;
    right: 7.1%;
    bottom: 12%;
    text-align: left;
    color: white;
    padding: 20px;

    h2 {
      font-size: 29px;
      font-weight: 500;
      letter-spacing: -0.8px;
      margin-bottom: 0.5rem;
      line-height: 38px;
    }
  }
`;

export const CallToAction = styled(Link)`
  display: inline-block;
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  font-weight: bold;
  background: var(--primary);
  font-size: 1.34rem;
`;