import React from 'react';
import { AlignedImageBlock } from '../../Blocks/AlignedImageBlock/AlignedImageBlock';
import { FlexibleRichTextBox } from '../FlexibleRichTextBox/FlexibleRichTextBox';
import { ImageBlock } from '../../Blocks/ImageBlock/ImageBlock';
import { VideoBlock } from '../../Blocks/VideoBlock/VideoBlock';

export const MainContentBlockHandler = (
  { content },
) => {
  if (Array.isArray(content) && content.map) {
    return content.map((contentBlock, i) => {
      switch (contentBlock.internal.type) {
        case 'ContentfulAlignedImageBlock':
          return (
              <AlignedImageBlock
                key={i}
                title={contentBlock.title}
                content={contentBlock.mainText}
                images={contentBlock.images}
                alignment={contentBlock.alignment}
              />
          );
        case 'ContentfulTextBlock':
          return (
              <FlexibleRichTextBox key={i} title={contentBlock.title} singleColumn={contentBlock.singleColumn} content={contentBlock.mainContent} />
          );
        case 'ContentfulImageBlock':
          return (
              <ImageBlock
                key={i}
                title={contentBlock.title}
                image={contentBlock.image}
              />
          );
        case 'ContentfulVideoBlock':
          return (
              <VideoBlock
                key={i}
                title={contentBlock.title}
                mediaSrc={contentBlock.videoUrl}
                description={contentBlock.description}
              />
          );
        default:
          console.warn(`Block type ${contentBlock.type} not found!`);
          return null;
      }
    });
  }
  return null;
};