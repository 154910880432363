import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ServicesHomeSliderStyles } from './ServicesStyles';
import useAllService from '../../hooks/use-all-service';

const ServicesHomeSlider = ({ title, subheading }) => {

  const items = useAllService();

  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [selectedImage, setSelectedImage] = useState(items[0]?.listingImage);

  useEffect(() => {
    // Set a timeout to change fade state after the transition duration
    const timeoutId = setTimeout(() => {
      setSelectedImage(selectedItem?.listingImage);
    }, 50); // Adjust this value to match your CSS transition duration

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [selectedItem]);

  let galleryImage = getImage(selectedImage);

  const handleItemMouseOver = (item) => {
    // Check if the mouse over link corresponds to the current selected item
    if (item === selectedItem) {
      return;
    }
    setSelectedItem(item);
  };

  return (
    <ServicesHomeSliderStyles>
      <h2>{title}<span>.</span></h2>
      <h3>{subheading}</h3>
      <div className="container">
        <div className="slider-container">
          <div className="left-panel">
            <ul>
              {items.map((item) => (
                <li key={item.title} onMouseOver={() => handleItemMouseOver(item)}>
                  <a href={`services/${item.url}`}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className={`right-panel`}>
            <div className="image-wrapper">
              <GatsbyImage image={galleryImage} alt={selectedItem.title} />
            </div>
          </div>
        </div>
      </div>
    </ServicesHomeSliderStyles>
  );
};

export default ServicesHomeSlider;