import styled from "styled-components"

export const TileStyles = styled.div`
    position: relative;

    .gatsby-image-wrapper-constrained {
        width:100%;
    }

    img {
          width: 100%;
        }
    .content {
        padding: 0 12px 24px 12px;
        margin: 0;
        h3 {
            font-size: 28px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        p {
            max-width: 600px;
            font-size: 30px;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: -0.8px;
            color: #332C28;
            margin-top: 0;
        }
        .readMe {
            position: relative;
            display: block;
        }
    }
`;