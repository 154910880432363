import * as React from "react";
import styled from 'styled-components';
import Layout from "../components/Layout";
import QuoteSlider from '../components/QuoteSlider/QuoteSlider';
import Seo from "../components/SEO";
import Services from "../components/Services/Services";
import SimpleBanner from "../components/SimpleBanner/SimpleBanner";
import useAllCampaigns from '../hooks/use-all-campaigns';
import useAllClients from '../hooks/use-all-clients';
import useAllServiceSlider from '../hooks/use-all-services-slider';
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import { MainContentBlockHandler } from '../components/MainContentBlockHandler/MainContentBlockHandler';
import { TileSlider } from "../components/TileSlider/TileSlider";
import { transformTileContent } from '../utils/transform-data-functions';

const StyledServices = styled(Services)`
    .container:first-child {
        padding-top: 120px;
        @media (max-width: 768px) {
          div:first-child {
            width: 100%;
          }
        }
    }
`;

const AboutTemplate = contentfulPage => {

  const clients = useAllClients();
  const campaigns = transformTileContent(useAllCampaigns());
  const services = transformTileContent(useAllServiceSlider());
  const clientQuotes = contentfulPage.clientQuotes;
  const mainContentBlock = contentfulPage.mainContentBlock;
  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title} subTitle={contentfulPage.subTitle?.subTitle} introductionParagraph={contentfulPage.introductionParagraph?.introductionParagraph} hasDot={true} />
        {clientQuotes && <QuoteSlider slides={clientQuotes} />}
        <MainContentBlockHandler content={mainContentBlock} />
        <StyledServices title="What we do" />
        <TileSlider heading="Campaigns" intro="Our amazing team deliver incredible results across a variety of specialist services." tiles={campaigns} ctaText="View All" ctaLink="/campaigns" />
        <TileSlider heading="Our Services" tiles={services} />
        <ClientSlider heading="Clients." logos={clients} />
      </Layout>
    </>
  )
}

export default AboutTemplate